import { useInView } from "react-intersection-observer";

const AboutUs = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  return (
    <div id="experience" className="container-white">
      <div
        ref={ref}
        className={`container ${
          inView ? "animation-bottom-end" : "animation-bottom-start"
        }`}
      >
        <div className="about-us-info">
          <div className="about-us-info__item">
            <div className="about-us-info__title">5+</div>
            <div className="about-us-info__content">
              лет опыта в предоставлении ЖКУ
            </div>
          </div>
          <div className="about-us-info__item">
            <div className="about-us-info__title">10,000+</div>
            <div className="about-us-info__content">
              клиентов, доверяющих нам благоустройство своих домов
            </div>
          </div>
          <div className="about-us-info__item">
            <div className="about-us-info__title">570+</div>
            <div className="about-us-info__content">
              успешно завершённых проектов
            </div>
          </div>
          <div className="about-us-info__item">
            <div className="about-us-info__title">&lt;30</div>
            <div className="about-us-info__content">
              дней хранение видеоматериалов
            </div>
          </div>
          <div className="about-us-info__item">
            <div className="about-us-info__title">3,500+</div>
            <div className="about-us-info__content">
              камер видеонаблюдения установлено
            </div>
          </div>
          <div className="about-us-info__item toggle_content">
            <div className="about-us-info__title">10+</div>
            <div className="about-us-info__content">
              городов Беларуси сотрудничает с нами
            </div>
            <div className="about-us-info__content second_content">
              Гродно, Гомель, Солигорск, Светлогорск, Орша, Молодечно,
              Новополоцк, Жлобин, Дзержинск, Фаниполь, Заславль, Осиповичи,
              Гатово, Мачулищи, Лесной, Марьина Горка.


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
